import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import './index.css';

const Team = () => {
  const teamList = [
    {
      id: 1,
      name: 'Rocco',
      desc:
        'Rocco co-founded Valor after experiencing firsthand the difficulties project owners face in accessing the carbon markets.\n\nIn his last role, Rocco worked on renewable energy infrastructure in emerging markets - struck by the bottlenecks, lack of transparency, and huge inefficiencies involved with bringing projects to market, he was inspired to do something about it.\n\nRocco holds a Bachelor of Arts in Philosophy, Politics, and Economics from the University of Oxford.',
      img: require('../../assets/images/team-rocco.png'),
      position: 'Co-Founder & CEO',
    },
    {
      id: 2,
      name: 'Rocky',
      desc:
        'Rocky co-founded Valor after seeing how many technological advances in fields like AI and language models were failing to be deployed in the fight against climate change.\n\nWith his rigorous background in quantitative methods, and armed with his experience in developing AI solutions while at TitanML, Rocky began hunting for ways to bring tools and innovation from academia and tech industry to the carbon markets.\n\nRocky holds a Master of Science in Theoretical Physics from Imperial College London, and a Master of Advanced Study in Mathematics from the University of Cambridge.',
      img: require('../../assets/images/team-rocky.png'),
      position: 'Co-Founder & CTO',
    },
    {
      id:3,
      name:'Arina',
      desc:'Arina is passionate about the application of novel technologies to achieve net-zero objectives, and currently sits on the Early Career Researchers’ Board of the UK AgriFood for Net-Zero project. Her expertise extends across the forestry and agricultural sectors, with a specialism in applications of satellite and ariel imagery.\n\nArina is currently studying towards a PhD at the University of Leicester, where she researches remote sensing methods to identify agroforestry and quantify its carbon storage in the United Kingdom. She holds a BA and MSci in Natural Sciences from the University of Cambridge.',
      img:require('../../assets/images/team-arina.png'),
      position:'Research Consultant'
    },
    {
      id: 4,
      name: 'Jyldyz',
      desc:
        'Jyldyz brings over 20 years of finance and accounting expertise, recognizing the impact of strategic financial planning on mining industry growth. She has developed financial plans, budgets, and forecasts for companies such as Cameco Gold Corporation, Kumtor Operating Company, and Médecins Sans Frontières Kyrgyzstan.\n\nAs CEO of BainGeo graphite mining for a decade, she managed administration, HR, financial, and legal operations, and drove team performance through mentoring and coaching. Bilingual in English, Russian, and Kyrgyz, Jyldyz connects diverse teams and stakeholders effectively.',
      img: require('../../assets/images/team-jyldyz.png'),
      // position: 'Co-Founder & CEO',
    },
    {
      id: 5,
      name: 'Andrew',
      desc:
        'Andrew, previously a senior manager in a FTSE 100 company, is a qualified accountant with broad experience in project management, FD, and VP roles, focusing on successful business turnarounds for medium and large business units. He has worked internationally for 25 years, including in the FSU and Central America, with deep expertise in accounting, operations systems, controls, reporting, and CAPEX allocation to maximize ROCE. \n\nAndrew is also a qualified engineer with technical experience in telecoms, aviation, and mining.',
      img: require('../../assets/images/team-andrew.png'),
      // position: 'Co-Founder & CTO',
    },
    {
      id:6,
      name:'Ruslan',
      desc:'',
      img:require('../../assets/images/team-arina.png'),
      // position:'Research Consultant'
    }
  ];

  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // 初始状态判断

  useEffect(() => {
    // 监听窗口大小变化
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);  // 判断是否小于700px
    };

    window.addEventListener("resize", handleResize);

    // 清除事件监听器
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showDrawer = (id) => {
    setDesc(teamList[id].desc)
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  // const [isHovered6, setIsHovered6] = useState(false);
  const slidingElementStyle = {
    position:"absolute",
    top: '0',
    left: '0',
    height: '29.79vw',
    zIndex: '100',
    transition: 'left 0.3s ease', // 动画过渡效果
    whiteSpace: 'pre-line',
  };
  const slidingElementStyle1 = {
    position:"absolute",
    width: '100%',
    height: '29.79vw',
    top: '0', // 根据 isHovered 控制 top 值
    left:'-100%',
    zIndex: '1',
    transition: 'left 0.3s ease', // 动画过渡效果
    whiteSpace: 'pre-line',
  };
  const slidingElementStyle2 = {
    position:"absolute",
    top: '0', // 根据 isHovered 控制 top 值
    left: 'calc(-100% - 1.74vw)',
    zIndex: '0',
    transition: 'left 0.3s ease', // 动画过渡效果
    height: '29.79vw',
    whiteSpace: 'pre-line',
  };
  const slidingElementStyle3 = {
    position:"absolute",
    top: '0', // 根据 isHovered 控制 top 值
    // right:'calc(50% + 12.5px)',
    left:'0',
    // width: '495px',
    width: '100%',
    zIndex: '2',
    transition: 'left 0.3s ease', // 动画过渡效果
    height: '29.79vw',
    whiteSpace: 'pre-line',
  };
  const slidingElementStyle4 = {
    position:"absolute",
    top: '0', // 根据 isHovered 控制 top 值
    // right:'calc(50% + 12.5px)',
    left:'calc(-100% - 1.74vw)',
    // width: '495px',
    width: '100%',
    zIndex: '97',
    transition: 'left 0.3s ease', // 动画过渡效果
    height: '29.79vw',
    whiteSpace: 'pre-line',
  };

  return (
    <>
      <div className="team" id="Team">
        <div className="team-content">
          <h1 className="team-title">Team</h1>
          <p className="team-index">/ 05</p>

          {/* <ul className='teams'>
            {
              teamList.map((item) => {
                return (
                  <li 
                      className='team-item' 
                      onMouseEnter={() => setIsHovered1(true)}
                      onMouseLeave={() => setIsHovered1(false)}
                      key={item.id}>
                      <img  src={item.img}alt=""></img>
                      {
                        item.id === 1 ? <div className='team-desc' style={isHovered1  ? slidingElementStyle : slidingElementStyle1}>
                        {item.desc} 
                      </div> : <div className='team-desc' style={isHovered2  ? slidingElementStyle3 : slidingElementStyle2}>
                        {item.desc}
                      </div>
                      }

                      <div className='team-item-content'>
                        <h1 className='team-item-title'>{item.name}</h1>
                        <p className='team-item-text'>{item.position}</p>
                      </div>
                  </li>
                )
               
              })
            }
          </ul> */}

          
          <ul className='teams'>
            <li className='team-item' 
              onMouseEnter={ !isMobile ? () => setIsHovered1(true) : undefined}
              onMouseLeave={() => setIsHovered1(false)}
              onClick={isMobile ? () => showDrawer(0) : undefined}>
              <img src={teamList[0].img}alt=''></img>
              {
                !isMobile && (
                  <div className='team-desc' style={isHovered1  ? slidingElementStyle : slidingElementStyle1}>
                    {teamList[0].desc}
                  </div>
                )
              }
              <div className='team-item-content'>
                <h1 className='team-item-title'>{teamList[0].name}</h1>
                <p className='team-item-text'>{teamList[0].position}</p>
              </div>
            </li>
            <li className='team-item'
              onMouseEnter={!isMobile ?() => setIsHovered2(true): undefined}
              onMouseLeave={() => setIsHovered2(false)}
              onClick={isMobile ? () => showDrawer(1) : undefined}>
              <img src={teamList[1].img} alt=''></img>
              {
                !isMobile && (
                  <div className='team-desc' style={isHovered2  ? slidingElementStyle3 : slidingElementStyle2}>
                    {teamList[1].desc}
                  </div>
                )
              }
              
              <div className='team-item-content'>
                <h1 className='team-item-title'>{teamList[1].name}</h1>
                <p className='team-item-text'>{teamList[1].position}</p>
              </div>
            </li>
            <li className='team-item' 
              onMouseEnter={ !isMobile ? () => setIsHovered3(true) : undefined}
              onMouseLeave={() => {setIsHovered3(false)}}
              onClick={isMobile ? () => showDrawer(2) : undefined}>
              <img src={teamList[2].img} alt=''></img>
              {
                !isMobile && (
                  <div className='team-desc' style={isHovered3  ? slidingElementStyle : slidingElementStyle4}>
                    {teamList[2].desc}
                  </div>
                )
              }
              <div className='team-item-content'>
                <h1 className='team-item-title'>{teamList[2].name}</h1>
                <p className='team-item-text'>{teamList[2].position}</p>
              </div>
            </li>
          </ul> 

          <ul className='teams top'>
            <li className='team-item' 
              onMouseEnter={ !isMobile ? () => setIsHovered4(true) : undefined}
              onMouseLeave={() => setIsHovered4(false)}
              onClick={isMobile ? () => showDrawer(3) : undefined}>
              <img src={teamList[3].img} alt=''></img>
              {
                !isMobile && (
                  <div className='team-desc' style={isHovered4  ? slidingElementStyle : slidingElementStyle1}>
                    {teamList[3].desc}
                  </div>
                )
              }
              <div className='team-item-content'>
                <h1 className='team-item-title'>{teamList[3].name}</h1>
                <p className='team-item-text'>{teamList[3].position}</p>
              </div>
            </li>
            <li className='team-item'
              onMouseEnter={!isMobile ?() => setIsHovered5(true): undefined}
              onMouseLeave={() => setIsHovered5(false)}
              onClick={isMobile ? () => showDrawer(4) : undefined}>
              <img src={teamList[4].img} alt=''></img>
              {
                !isMobile && (
                  <div className='team-desc' style={isHovered5  ? slidingElementStyle3 : slidingElementStyle2}>
                    {teamList[4].desc}
                  </div>
                )
              }
              
              <div className='team-item-content'>
                <h1 className='team-item-title'>{teamList[4].name}</h1>
                <p className='team-item-text'>{teamList[4].position}</p>
              </div>
            </li>
            {/* <li className='team-item' 
              onMouseEnter={ !isMobile ? () => setIsHovered6(true) : undefined}
              onMouseLeave={() => {setIsHovered6(false)}}
              onClick={isMobile ? () => showDrawer(5) : undefined}>
              <img src={teamList[5].img} alt=''></img>
              {
                !isMobile && (
                  <div className='team-desc' style={isHovered6  ? slidingElementStyle : slidingElementStyle4}>
                    {teamList[5].desc}
                  </div>
                )
              }
              <div className='team-item-content'>
                <h1 className='team-item-title'>{teamList[5].name}</h1>
                <p className='team-item-text'>{teamList[5].position}</p>
              </div>
            </li> */}
          </ul> 
          </div>
         
        </div>

      <Drawer
        width={500}
        placement={'bottom'}
        onClose={onClose}
        open={open}
        closeIcon={
          <img
            src={require('../../assets/images/close-icon.png')}
            alt="close"
            style={{ width: '20px' }}
          />
        }
        style={{
          backgroundColor: '#F3FD65',
          fontSize: '16px',
          fontFamily: 'Neue Haas Grotesk Display Pro',
          lineHeight: '24px',
        }}
      >
        <p style={{ whiteSpace: 'pre-line' }}>{desc}</p>
      </Drawer>
    </>
  );
};

export default Team;
