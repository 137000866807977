
import React, { useState } from 'react';
import './index.css'
const Footer = () => {
  const touchList = [
      {
          id:1,
          name:'Email',
          icon: require("../../assets/images/menu-email.png"),
          iconActive:require("../../assets/images/menu-email-hover.png"),
          url:'mailto:rocco@valorcarbon.com,rocky@valorcarbon.com'
      },
      {
          id:2,
          name:'Linkedin',
          icon: require("../../assets/images/menu-linkedin.png"),
          iconActive:require("../../assets/images/menu-linkedin-hover.png"),
          url:'https://www.linkedin.com/company/valor-carbon'
      }
  ]
  const [hoveredItem, setHoveredItem] = useState(null);
    return (
        <div className="footer">
          <div className='footer-inner'>
          <div className="footer-left">
            <p className='logo'>
              <img src={[require("../../assets/images/logo-menu.png")]}alt=""></img>
              Valor
            </p>
            <div className='footer-content'>
              <div className='box'>
                <iframe src='https://my.spline.design/abstract3diconset-2287e08b78039767108949cee9852adb/'  title='1' frameborder='0' width='100%' height='100%'></iframe>
                {/* <script type="module" src="https://unpkg.com/@splinetool/viewer@1.9.27/build/spline-viewer.js"></script>
                <spline-viewer url="https://prod.spline.design/itOq8Zg24E8eCFdj/scene.splinecode" ></spline-viewer> */}
              </div>
              <h1 className='footer-title'>Your Partner for Unlocking Climate Finance</h1>
            </div>
           
          </div>
          <div className="footer-right">
            Get in touch:
          <ul>
          {
              touchList.map((item) => {
                  return (
                    <li className='footer-right-item'
                    key={item.id}
                    onMouseEnter={() => setHoveredItem(item.id)} 
                    onMouseLeave={() => setHoveredItem(null)}>
                    <a href={item?.url} target="_blank" rel="noreferrer">
                       <img src={hoveredItem === item.id ? item.iconActive : item.icon}alt=""></img>
                       {item.name}
                    </a>
                  </li>
                  )
              })
          }
            {/* <li className='footer-right-item'>
              <img src={[require("../../assets/images/menu-email.png")]}alt=""></img>
              Email
            </li>
            <li className='footer-right-item'>
              <a href='https://www.baidu.com' target="_blank">
                <img src={[require("../../assets/images/menu-email.png")]}alt=""></img>
                Linkedin
              </a>
            </li> */}
          </ul>
          </div>
          </div>
        </div>
    )
}

export default Footer