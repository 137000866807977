
import React from 'react';
// import { useState } from 'react';
import './index.css'
// import SplineViewer from '../SplineViewer/index'
const Mission = () => {
  const missionList = [
    {
      index:'01',
      icon:require("../../assets/images/mission-01.png"),
      desc:'Streamline processes and boost efficiency through a radical focus on technological excellence.'
    },
    {
      index:'02',
      icon:require("../../assets/images/mission-02.png"),
      desc:'Work with project owners and local communities to develop high-quality carbon projects with integrity and transparency.'
    },
    {
      index:'03',
      icon:require("../../assets/images/mission-03.png"),
      desc:'Bring climate finance to overlooked and underserved regions, leveraging the growing carbon markets.'
    },
  ]
    return (
        <div class="Mission" id='Mission'>
            <div className='left-margin'>
              <p class="title">Mission</p>
              <p class="sub-title"> Fragmented processes and limited access are holding back the carbon markets, and in turn the fight against climate change. </p>
              <p class="sub-title"> We will strengthen the carbon markets by sourcing and developing carbon projects with greater efficiency, integrity, and reach. </p>
            </div>
            <p class="index">/ 02</p>
            <div class="box">
              {/* <iframe src='https://prod.spline.design/UFQxZtR6XVHxZcxK/scene.splinecode' frameborder='0' width='100%' height='100%'></iframe> */}
              {/* <SplineViewer /> */}
              <script type="module" src="https://unpkg.com/@splinetool/viewer@1.9.27/build/spline-viewer.js"></script>
              <spline-viewer url="https://prod.spline.design/1aAFqWLLXVsdJKOM/scene.splinecode"></spline-viewer>
            </div>
            
           <div className="mission-container">
           <p className='our-mission'>Our mission is to:</p>
           <ul className='missions'>
            {
              missionList.map((item) => {
                return(
                  <li className='mission-item'>
                    <img src={item.icon}alt=""></img>
                    <div className='mission-item-content'>
                      <p className='mission-item-index'>{item.index}</p>
                      <p className='mission-item-text'>{item.desc}</p>
                    </div>
                  </li>
                )
              })
            }
           </ul>
           
            {/* <ul className='missions'>
              <li className='mission-item'>
                <img src={[require("../../assets/images/mission-01.png")]}alt=""></img>
                <div className='mission-item-content'>
                  <p className='mission-item-index'>01</p>
                  <p className='mission-item-text'>Streamline processes to boost market efficiency.</p>
                </div>
              </li>
              <li className='mission-item'>
                <img src={[require("../../assets/images/mission-02.png")]}alt=""></img>
                <div className='mission-item-content'>
                  <p className='mission-item-index'>02</p>
                  <div className='mission-item-text'>Enhance transparency to ensure market integrity.</div>
                </div>
              </li>
              <li className='mission-item'>
                <img src={[require("../../assets/images/mission-03.png")]}alt=""></img>
                <div className='mission-item-content'>
                  <p className='mission-item-index'>03</p>
                  <p className='mission-item-text'>Expand access to include untapped geographies and increase participation in carbon sequestration.</p>
                </div>
              </li>
            </ul> */}
           </div>
        </div>
    )
}

export default Mission