// import Header from '../../components/Header';
import Services from '../../components/Services';
import Mission from '../../components/Mission';
import Approach from '../../components/Approach';
import Portfolio from '../../components/Portfolio';
import Team from '../../components/Team';
import News from '../../components/News';
import Footer from '../../components/Footer';
import './index.css'


function Home() {
  return (
    <div className="home" id='Home'>
      <Services />
      <Mission />
      <div className='home-bg'>
        <Approach />
        <Portfolio />
        <Team />
        <News />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
