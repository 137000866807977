
// import React from 'react';
// import { useState } from 'react';
import './index.css'
const News = () => {
  // const newsList = [
  //   {
  //     date:'[JUL 31，2024]',
  //     img:require("../../assets/images/news-01.png"),
  //     desc:'First successful case testing the integrationof Bequant and lXC Soft in Brazil'
  //   },
  //   {
  //     date:'[JUL 31，2024]',
  //     img:require("../../assets/images/news-02.png"),
  //     desc:'First successful case testing the integrationof Bequant and lXC Soft in Brazil'
  //   },
  //   {
  //     date:'[JUL 31，2024]',
  //     img:require("../../assets/images/news-03.png"),
  //     desc:'First successful case testing the integrationof Bequant and lXC Soft in Brazil'
  //   }
  // ]
    // return (
    //     <div class="news" id='News'>
    //       <h1 className='news-title'>Newsroom</h1>
    //       <p className="news-index">/ 06</p>
    //       <ul className='news-list'>
    //         {
    //           newsList.map(item => {
    //             return(
    //               <li className='news-item'>
    //                   <p className='news-item-date'>{item.date}</p>
    //                   <img src={item.img}alt=""></img>
    //                   <div className='news-item-content'>
    //                     <p className='news-item-text'>{item.desc}</p>
    //                   </div>
    //                   <p className='news-item-share'> <img src={[require("../../assets/images/share-icon.png")]} alt=""></img></p>
    //               </li>
    //             )
    //           })
    //         }
    //       </ul>
    //       {/* <ul className='news-list'>
    //         <li className='news-item'>
    //           <p className='news-item-date'>[JUL 31，2024]</p>
    //           <img src={[require("../../assets/images/news-01.png")]}alt=""></img>
    //           <div className='news-item-content'>
    //             <p className='news-item-text'>First successful case testing the integrationof Bequant and lXC Soft in Brazil</p>
    //           </div>
    //           <p className='news-item-share'>SEE ARTICLE <img src={[require("../../assets/images/share-icon.png")]}alt=""></img></p>
    //         </li>
    //         <li className='news-item'>
    //           <p className='news-item-date'>[JUL 31，2024]</p>
    //             <img src={[require("../../assets/images/news-02.png")]}alt=""></img>
    //             <div className='news-item-content'>
    //               <p className='news-item-text'>First successful case testing the integrationof Bequant and lXC Soft in Brazil</p>
    //             </div>
    //             <p className='news-item-share'>SEE ARTICLE</p>
    //         </li>
    //         <li className='news-item'>
    //           <p className='news-item-date'>[JUL 31，2024]</p>
    //             <img src={[require("../../assets/images/news-03.png")]}alt=""></img>
    //             <div className='news-item-content'>
    //               <p className='news-item-text'>First successful case testing the integrationof Bequant and lXC Soft in Brazil</p>
    //             </div>
    //             <p className='news-item-share'>SEE ARTICLE</p>
    //         </li>
    //       </ul> */}
    //     </div>
    // )
}

export default News