import React, { useState } from 'react';
import './index.css'

const Menu = ({ onData }) => {
    const [isMobile] = useState(window.innerWidth < 768);
    // const [setIsMobile] = useState(window.innerWidth < 768);
    const touchList = [
        // {
        //     id:1,
        //     name:'Phone',
        //     icon: require("../../assets/images/menu-phone.png"),
        //     iconActive:require("../../assets/images/menu-phone-hover.png"),
        // },
        {
            id:2,
            name:'Email',
            icon: require("../../assets/images/menu-email.png"),
            iconActive:require("../../assets/images/menu-email-hover.png"),
            url: 'mailto:rocco@valorcarbon.com,rocky@valorcarbon.com'
        },
        {
            id:3,
            name:'Linkedin',
            icon: require("../../assets/images/menu-linkedin.png"),
            iconActive:require("../../assets/images/menu-linkedin-hover.png"),
            url:'https://www.linkedin.com/company/valor-carbon'
        },
        // {
        //     id:4,
        //     name:'X',
        //     icon: require("../../assets/images/menu-social.png"),
        //     iconActive:require("../../assets/images/menu-social-hover.png"),
        //     url:'https://www.linkedin.com'
        // },
        // {
        //     id:5,
        //     name:'location',
        //     icon: require("../../assets/images/menu-location.png"),
        //     iconActive:require("../../assets/images/menu-location-hover.png"),
        // },
    ]
    const [hoveredItem, setHoveredItem] = useState(null);
    const closeMenu = () => {
        // 调用父组件传递的回调函数，传递数据
        onData('close menu');
      };
    const styles = {
        hidden: {
            display: 'none',
        },
        visible: {
            display: 'block',
        }
    };
    return (
        <div class="menu">
            {/* menu-top */}
            <div class="menu-top">
                <span class="logo">
                    <img src={[require("../../assets/images/logo-menu.png")]}alt=""></img>
                    Valor
                </span>
                <span class="close" onClick={closeMenu}>
                    <img src={[require("../../assets/images/close.png")]}alt=""></img>
                </span>
            </div>
            <div class="menu-content">
                {/* menu-left */}
                <ul class="menu-left">
                    <li>
                        <a href="#Services">
                            <img style={isMobile ? styles.hidden : styles.visible} src={[require("../../assets/images/arrow-left.png")]}alt=""></img>
                            Home
                        </a>
                    </li>
                    <li>
                        <a href="#Mission">
                            <img style={isMobile ? styles.hidden : styles.visible}  src={[require("../../assets/images/arrow-left.png")]}alt=""></img>
                            Mission
                        </a>
                    </li>
                    <li>
                        <a href="#Approach">
                            <img style={isMobile ? styles.hidden : styles.visible}  src={[require("../../assets/images/arrow-left.png")]}alt=""></img>
                            Our Approach
                        </a>
                    </li>
                    <li>
                        <a href="#Team">
                            <img style={isMobile ? styles.hidden : styles.visible}  src={[require("../../assets/images/arrow-left.png")]}alt=""></img>
                            Team
                        </a>
                    </li>
                    {/* <li>
                        <a href="#News">
                            <img style={isMobile ? styles.hidden : styles.visible}  src={[require("../../assets/images/arrow-left.png")]}alt=""></img>
                            News
                        </a>
                    </li> */}
                </ul>
                {/* menu-right */}
                <ul class="menu-right">
                    <li>
                        
                        Get in touch
                    </li>
                    {
                        touchList.map((item) => {
                            return (
                                <li
                                    key={item.id}
                                    onMouseEnter={() => setHoveredItem(item.id)} // 鼠标悬停，设置当前元素的 id
                                    onMouseLeave={() => setHoveredItem(null)} // 鼠标离开，清空悬停状态
                                >
                                    <a href={item?.url} target="_blank" rel="noreferrer">
                                        <img src={hoveredItem === item.id ? item.iconActive : item.icon}alt=""></img>
                                        {item.name}
                                    </a>
                                </li>
                            )
                        })
                    }
                    
                </ul>
                <p>© 2024 Valor</p>
            </div>
            
        </div>
    )
}

export default Menu