
import React, { useState,useEffect } from 'react';
import './index.css'
import Menu from '../Menu/index'

const Header = () => {
    const [isHovered, setIsHovered] = useState(false);
    // const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
     // 用于存储 top 值
  const [topValue, setTopValue] = useState('-3000px');

  // 当屏幕宽度发生变化时更新 top 值
  useEffect(() => {
    const handleResize = () => {
      // 获取当前窗口宽度
      const windowWidth = window.innerWidth;

      // 如果宽度大于2000，top设置为100px，否则为-2183px
      if (windowWidth > 2000) {
        setTopValue('-56vw');
      } else {
        setTopValue('-1183px');
      }
    };

    // 初始化时立即检查窗口宽度
    handleResize();

    // 添加窗口尺寸变化监听器
    window.addEventListener('resize', handleResize);

    // 组件卸载时清除监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const slidingElementStyle = {
        position:"absolute",
        top: '0px', // 根据 isHovered 控制 top 值
        left:'0',

        // display: 'block',
        width: '100%',
        zIndex: '999',
        transition: 'top 0.9s ease', // 动画过渡效果
      };
      const slidingElementStyle1 = {
        position:"absolute",
        width: '100%',
        // display: 'none',
        // top: '-1183px', // 根据 isHovered 控制 top 值
        top: topValue, // 根据 isHovered 控制 top 值
        left:'0',
        zIndex: '999',
        transition: 'top 0.s ease', // 动画过渡效果
      };

      const handleClick = () => {
        console.log("handleClick");
      }
      

  // 处理从子组件传来的数据
  const handleMenuClose = (data) => {
    setIsHovered(!isHovered);
  };
    return (
        <div class="header">
            {/* Menu */}
          <div style={isHovered  ? slidingElementStyle : slidingElementStyle1}>
            <Menu onData={handleMenuClose}/>
          </div>
            
            
            <div class="left">
                <img src={[require("../../assets/images/logo.png")]}alt=""></img>
                <p>Valor</p>
            </div>
            <div class="right" 
                onClick={handleClick}
                onMouseEnter={() => setIsHovered(true)}
            
            >
                <img src={[require("../../assets/images/menu.png")]}alt=""></img>
            </div>
        </div>
    )
}

export default Header