
import Header from '../Header/index'
// import SplineViewer from '../SplineViewer/index'
import './index.css'

const Services = () => {
    return (
        <div class="Services" id="Services">
            <Header />
           <div className='left-margin'>
            <div class="text">
                    Your Partner for Unlocking Climate Finance
                </div>
                <div class="sub-text">
                    Democratizing access to the global carbon markets, leveraging technology and deep local market knowledge.
                </div>
                <div class="explore">
                EXPLORE
                <span class="explore-button">
                    <img src={[require("../../assets/images/arrow.png")]}alt=""></img>
                </span>
            </div>
           </div>
            
            <img class="banner-img" src={[require("../../assets/images/image.png")]}alt=""></img>
        </div>
    )
}

export default Services