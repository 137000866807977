
import React from 'react';
// import { useState } from 'react';
import './index.css'
const Approach = () => {
  const approachList = [
    {
      index:'01',
      name:'Advisory',
      desc:'Carbon markets are complex - the scale and breadth of opportunities can be hidden. Valor will be there at the very beginning to advise you. We will conduct a review of your overall situation and assess potential projects you could pursue.'
    },
    {
      index:'02',
      name:'Project Design',
      desc:'Aligning carbon projects with international standards can be challenging. Valor will design your project from the ground up. Our data-driven approach ensures high-quality design, which is critical for achieving real impact and higher credit prices.'
    },
    {
      index:'03',
      name:'Implementation',
      desc:'Project owners can struggle with the operational hurdles to successful implementation. Valor will directly support you throughout this process. Our software will ease project management, and where necessary we will station teams locally to assist.'
    },
    {
      index:'04',
      name:'Transaction',
      desc:'Finding and securing buyers for carbon credits can be difficult in this still very inefficient market. Valor will be there to help structure and advise on the sale of your carbon credits, structuring the transaction to suit your needs.'
    },
  ]
    return (
        <div class="approach" id='Approach'>
          <h1 className='approach-title'>Our Approach</h1>
          <p className="approach-index">/ 03</p>
          <img className='approach-img' src={[require("../../assets/images/approach-01.png")]}alt=""></img>
          <ul className='approachs'>
            {
              approachList.map(item => {
                return (
                  <li className='approach-item'>
                    <span className='approach-item-index'>{item.index}</span>
                    <h1 className='approach-item-title'>{item.name}</h1>
                    <p className='approach-item-text'>{item.desc}</p>
                  </li>
                )
              })
            }
          </ul>
          {/* <ul className='approachs'>
            <li className='approach-item'>
              <span className='approach-item-index'>01</span>
              <h1 className='approach-item-title'>AI-enabled processing</h1>
              <p className='approach-item-text'>Generate application packs in days, not months, using LLMs. Project developers simply provide their existing documentation, and the AI drafts the necessary documents. These drafts are then reviewed by in-house experts before being submitted to a third-party issuer.</p>
            </li>
            <li className='approach-item'>
              <span className='approach-item-index'>02</span>
              <h1 className='approach-item-title'>AI-enabled processing</h1>
              <p className='approach-item-text'>Generate application packs in days, not months, using LLMs. Project developers simply provide their existing documentation, and the AI drafts the necessary documents. These drafts are then reviewed by in-house experts before being submitted to a third-party issuer.</p>
            </li>
            <li className='approach-item'>
              <span className='approach-item-index'>03</span>
              <h1 className='approach-item-title'>AI-enabled processing</h1>
              <p className='approach-item-text'>Generate application packs in days, not months, using LLMs. Project developers simply provide their existing documentation, and the AI drafts the necessary documents. These drafts are then reviewed by in-house experts before being submitted to a third-party issuer.</p>
            </li>
          </ul> */}
        </div>
    )
}

export default Approach