
import React, { useState } from 'react';
import { Modal } from 'antd';
// import { Button } from 'antd';
import './index.css'
const Portfolio = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // view project details
    const viewProject = () => {
      setIsModalOpen(true);
    }
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    // const projectIndexs = [
    //   {
    //     num:'22+',
    //     name:'Hectares'
    //   },
    //   {
    //     num:'69+',
    //     name:'Trees Planted'
    //   },
    //   {
    //     num:'69+',
    //     name:'Locals employed'
    //   },
    //   {
    //     num:'69+',
    //     name:'Community benefits'
    //   },
    //   {
    //     num:'69+',
    //     name:'Tons of CO2 Sequestered'
    //   },
    // ];
    return (
      <>
        <div class="portfolio" id='Portfolio'>
          <h1 className='portfolio-title'>Portfolio</h1>
          <p className='portfolio-introduce'>Valor works on projects with measurable carbon removal potential and tangible co-benefits for local communities. We prioritize nature-based carbon removal projects, such as reforestation and regenerative agriculture, as well as certain avoided-emission projects in the waste and energy efficiency sectors.</p>
          <p className="portfolio-index">/ 04</p>
          <ul className='portfolios'>
            <li className='portfolio-item' onClick={viewProject}>
              <img src={[require("../../assets/images/portfolio-summit-01.png")]}alt=""></img>
              <div className='portfolio-item-content'>
                <span className='portfolio-item-index'>01</span>
                <h1 className='portfolio-item-title'>Project Summit</h1>
                <p className='portfolio-item-text'> Valor is spearheading a 25,000 hectare forestation and sustainable orchard farming project in Central Asia. The project will plant a mix of conifers and harvest-bearing trees, yielding high-quality carbon removal credits and sustainable fruit and nut harvests.</p>
              </div>
            </li>
          </ul>
        </div>
        {/* Modal */}
        <Modal className='project-modal'
         title="" open={isModalOpen}
         width={'54.7%'}
         bodyStyle={{ padding: '16px' }} 
         onCancel={handleCancel}
         footer={null}
         closeIcon={<img src={[require("../../assets/images/close-icon.png")]} alt="close" style={{ width: '2.8vw',marginTop: '17px',
          marginRight: '58px' }} />}
          >
            <img className='project-img' src={[require("../../assets/images/project-summit-01.png")]}alt=""></img>
            <div className='project-desc'>
              <h1 className='project-desc-name'>Project Summit</h1>
              <p className='project-desc-text'>
              Valor is spearheading a 25,000 hectare forestation and sustainable orchard farming project in Central Asia. The project will plant a mix of conifers and harvest-bearing trees, yielding high-quality carbon removal credits and sustainable fruit and nut harvests.
              </p>
              <ul className='project-index-list'>
                {/* {
                  projectIndexs.map((item,index) => {
                    <li key={index} className='project-index-item'>
                      <div className='item-num'>
                        <span>$</span>
                        {item.num}
                      </div>
                      <p className='item-name'>{item.name}</p>
                    </li>
                  })
                } */}
                 <li className='project-index-item'>
                    <div className='item-num'>
                      <span></span>
                      25,000
                    </div>
                    <p className='item-name'>Hectares</p>
                </li>
                <li className='project-index-item'>
                    <div className='item-num'>
                      <span></span>
                      10 million+
                    </div>
                    <p className='item-name'>Number of Trees</p>
                </li>
                <li className='project-index-item'>
                    <div className='item-num'>
                      <span></span>
                      10+
                    </div>
                    <p className='item-name'>Local Species</p>
                </li>
                <li className='project-index-item'>
                    <div className='item-num'>
                      <span></span>
                      500+
                    </div>
                    <p className='item-name'>Local Employment Opportunities</p>
                </li>
                <li className='project-index-item'>
                    <div className='item-num'>
                      <span>$</span>
                      100 million+
                    </div>
                    <p className='item-name'>Potential Contribution to Local Economy</p>
                </li>
              </ul>
              <img className='project-img mb35' src={[require("../../assets/images/project-summit-02.png")]}alt=""></img>
              <img className='project-img mb35' src={[require("../../assets/images/project-summit-03.png")]}alt=""></img>
              <img className='project-img' src={[require("../../assets/images/project-summit-04.png")]}alt=""></img>
            </div>
      </Modal>
        </>
    )
}

export default Portfolio